import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Determine the environment (development, production, staging)
// const environment = process.env.NODE_ENV || "development";

// Conditionally initialize Sentry based on the environment
// if (environment === "production" || environment === "staging") {
//   Sentry.init({
//     dsn: "https://bcd98ec1a8ce7e9951c3d1125287591e@o4506604826525696.ingest.sentry.io/4506607912615936",
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//       }),
//       new Sentry.Replay({
//         maskAllText: false,
//         blockAllMedia: false,
//       }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
