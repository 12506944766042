import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

const HomeNew = lazy(() => import("./views/Home/HomeNew"));
const Cart = lazy(() => import("./views/Cart"));
const AfterPayment = lazy(() => import("./views/AfterPayment"));
const PaymentFail = lazy(() => import("./views/PaymentFail"));
const MyOrders = lazy(() => import("./views/Account/MyOrders"));
const OrderDetails = lazy(() => import("./views/Account/OrderDetails"));

const loadingUI = (
  <div className="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={loadingUI}>
              <HomeNew />
            </Suspense>
          }
        />
        <Route
          path="/cart"
          element={
            <Suspense fallback={loadingUI}>
              <Cart />
            </Suspense>
          }
        />
        <Route
          path="/paymentsuccess"
          element={
            <Suspense fallback={loadingUI}>
              <AfterPayment />
            </Suspense>
          }
        />
        <Route
          path="/paymentfailed"
          element={
            <Suspense fallback={loadingUI}>
              <PaymentFail />
            </Suspense>
          }
        />
        <Route
          path="/myorders"
          element={
            <Suspense fallback={loadingUI}>
              <MyOrders />
            </Suspense>
          }
        />
        <Route
          path="/orderdetails"
          element={
            <Suspense fallback={loadingUI}>
              <OrderDetails />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
